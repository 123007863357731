import type { User, Group } from '@microsoft/microsoft-graph-types-beta';
import type { GroupUpdateOperation } from '../../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RBACGroup, RBACUser } from '../../../types';

import { msalInstance, acquireAccessToken } from '../../authentication/services/msal';

import { toast } from 'react-toastify';

// Graph API response type
interface GraphResponse<T> {
  '@odata.context': string;
  '@odata.nextLink'?: string;
  value: T[];
}

// Helper function to fetch all pages of a Graph API response
const getAllResults = async <T>(fetchWithBQ: any, nextLink: string): Promise<T[]> => {
  const results: T[] = [];
  while (nextLink) {
    const response = (await fetchWithBQ(nextLink)) as { data: GraphResponse<T> };
    results.push(...response.data.value);
    nextLink = response.data['@odata.nextLink'] ?? '';
  }
  return results;
};

export const graphApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://graph.microsoft.com/beta',
    prepareHeaders: async (headers) => {
      const token = await acquireAccessToken(msalInstance);
      headers.set('Authorization', `Bearer ${token}`);
      headers.append('ConsistencyLevel', 'eventual');
      return headers;
    },
  }),

  tagTypes: ['Group', 'User'],

  endpoints: (builder) => ({
    // Get all users
    getUsers: builder.query<RBACUser[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const users: User[] = await toast.promise(
          getAllResults(
            fetchWithBQ,
            "/users?$select=id,userPrincipalName,displayName,accountEnabled,jobTitle,officeLocation&$filter=(userType eq 'Member' and companyName ne null)&$count=true"
          ),
          {
            success: 'User list loaded',
            pending: 'Loading users list',
            error: 'Failed to load users list',
          },
          { closeOnClick: true }
        );
        // Convert Users to RBACUsers (remove users with missing displayName or userPrincipalName)
        return {
          data: users
            .map((user) => ({
              id: user.id!,
              displayName: user.displayName!,
              userPrincipalName: user.userPrincipalName!,
              accountEnabled: user.accountEnabled!,
              jobTitle: user.jobTitle!,
              officeLocation: user.officeLocation!,
            }))
            .filter((user) => user.displayName && user.userPrincipalName),
        };
      },
    }),

    // Get all Role groups that the user is a member of
    getUserGroupMembership: builder.query<RBACGroup[], string>({
      async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {
        // Fetch all pages of user's group membership
        const groups: Group[] = await getAllResults<Group>(
          fetchWithBQ,
          `/users/${id}/memberOf?$count=true&$search="displayName:Role_"&$select=id,displayName`
        );
        // Convert Groups to RBACGroups (remove groups with missing displayName)
        return {
          data: groups
            .map((group) => ({
              id: group.id!,
              displayName: group.displayName!,
              description: group.description ?? undefined,
              transitive: false,
            }))
            .filter((group) => group.displayName),
        };
      },
      providesTags: (result, error, id) => [{ type: 'User', id: id }],
    }),

    // Get all Role groups that the user is a transitive member of
    getUserTransitiveGroupMembership: builder.query<RBACGroup[], string>({
      async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {
        // Fetch all pages of user's group membership
        const groups: Group[] = await getAllResults<Group>(
          fetchWithBQ,
          `/users/${id}/transitiveMemberOf?$count=true&$search="displayName:Role_"&$select=id,displayName`
        );
        // Convert Groups to RBACGroups (remove groups with missing displayName)
        return {
          data: groups
            .map((group) => ({
              id: group.id!,
              displayName: group.displayName!,
              description: group.description ?? undefined,
              transitive: true,
            }))
            .filter((group) => group.displayName),
        };
      },
    }),

    // Get all Role groups
    getRoleGroups: builder.query<RBACGroup[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        // Fetch all pages of groups
        const groups: Group[] = await getAllResults<Group>(
          fetchWithBQ,
          "/groups?$count=true&$filter=startswith(displayName,'Role_')&$select=id,displayName"
        );
        // Convert Groups to RBACGroups (remove groups with missing displayName)
        return {
          data: groups
            .map((group) => ({
              id: group.id!,
              displayName: group.displayName!,
              description: group.description ?? undefined,
              transitive: false,
            }))
            .filter((group) => group.displayName),
        };
      },
    }),

    // Get all ACL groups that a list of Role groups is a member of
    getRoleGroupACLGroupMembership: builder.query<RBACGroup[], string[]>({
      // TODO: There might be too much logic in here
      async queryFn(roleGroupIds, _queryApi, _extraOptions, fetchWithBQ) {
        const [...ACLGroups] = await Promise.all(
          roleGroupIds.map(async (roleGroupId) => {
            // Fetch all pages of group's group membership
            const aclGroupResults: Group[] = await getAllResults<Group>(
              fetchWithBQ,
              `/groups/${roleGroupId}/memberOf?$count=true&$search="displayName:ACL_"&$select=id,displayName`
            );
            return aclGroupResults.map((aclGroup) => ({
              id: aclGroup.id!,
              displayName: aclGroup.displayName!,
              parentGroupID: roleGroupId,
            }));
          })
        );
        return {
          data: ACLGroups.flat().sort((a, b) => a.displayName!.localeCompare(b.displayName!)),
        };
      },
    }),

    // Get all ACL groups
    getACLGroups: builder.query<RBACGroup[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        // Fetch all pages of groups
        const groups: Group[] = await getAllResults<Group>(
          fetchWithBQ,
          '/groups?$count=true&$search="displayName:ACL_"&$select=id,displayName'
        );
        // Convert Groups to RBACGroups (remove groups with missing displayName)
        return {
          data: groups
            .map((group) => ({
              id: group.id!,
              displayName: group.displayName!,
              description: group.description ?? undefined,
              transitive: false,
            }))
            .filter((group) => group.displayName),
        };
      },
    }),

    addGroupMember: builder.mutation<void, GroupUpdateOperation>({
      query: (operation) => ({
        url: `/groups/${operation.groupId}/members/$ref`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          '@odata.id': `https://graph.microsoft.com/beta/directoryObjects/${operation.memberId}`,
        },
      }),
      async onQueryStarted(operation, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const patchResult = dispatch(
            graphApi.util.updateQueryData('getUserGroupMembership', operation.memberId, (data) => {
              return [
                ...data,
                {
                  id: operation.groupId,
                  displayName: operation.groupDisplayName,
                  transitive: false,
                },
              ];
            })
          );
        } catch {}
      },
    }),

    removeGroupMember: builder.mutation<void, GroupUpdateOperation>({
      query: (operation) => ({
        url: `/groups/${operation.groupId}/members/${operation.memberId}/$ref`,
        method: 'DELETE',
      }),
      async onQueryStarted(operation, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const patchResult = dispatch(
            graphApi.util.updateQueryData('getUserGroupMembership', operation.memberId, (data) => {
              return data.filter((group) => group.id !== operation.groupId);
            })
          );
        } catch {}
      },
    }),
  }),

  reducerPath: 'graphApi',
});

export const {
  useGetUsersQuery,
  useGetUserGroupMembershipQuery,
  useGetRoleGroupsQuery,
  useGetACLGroupsQuery,
  useGetRoleGroupACLGroupMembershipQuery,
  useGetUserTransitiveGroupMembershipQuery,
  useAddGroupMemberMutation,
  useRemoveGroupMemberMutation,
  //useUpdateGroupMembersMutation,
} = graphApi;
