import { useNavigate } from 'react-router-dom';
import Autocomplete from './UserAutocomplete';
import type { RBACUser } from '../../../types';
import { useState } from 'react';

type UserSelectProps = {
  users: RBACUser[] | undefined;
  selectedId?: string;
};

export default function UserSelect({ users, selectedId }: UserSelectProps) {
  // Navigate to user page on select
  const navigate = useNavigate();
  const [showDisabled, setShowDisabled] = useState(false);
  const setUser = (user: RBACUser) => {
    navigate(`/users/${user.id}`);
  };

  if (!users) {
    return <div>No users to display</div>;
  }
  // filter out users without displayName, id, and filter out disabled users if showDisabled is false
  const userList = users.filter(
    (user) => user.displayName && user.id && (showDisabled || user.accountEnabled)
  );

  return (
    <div>
      <div className="font-bold pb-2">Search for user/UPN:</div>
      <Autocomplete items={userList} onSelect={setUser} selectedId={selectedId} />
      <label className="cursor-pointer label">
        <span className="label-text text-xs">Show Disabled Users</span>
        <input
          id="showDisabledUsers"
          type="checkbox"
          className="toggle toggle-primary toggle-sm"
          checked={showDisabled}
          onChange={() => setShowDisabled(!showDisabled)}
        />
      </label>
    </div>
  );
}
