import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { RBACGroup } from '../../../types';

type Props = {
  displayName: string;
  roleId: string;
  selected?: boolean;
  transitive?: boolean;
  deleted?: boolean;
  added?: boolean;
  onClick: (groupId: string) => void;
  onDelete?: (group: RBACGroup) => void;
};

export default function GroupDisplayItem({
  displayName: name,
  selected = false,
  transitive = false,
  roleId,
  deleted = false,
  added = false,
  onClick,
  onDelete,
}: Props) {
  const handleDeleteClick = (e: React.MouseEvent) => {
    // Don't let the click bubble up to the parent element
    e.stopPropagation();
    // onDelete is optional, so we need to check if it's defined
    if (onDelete) onDelete({ id: roleId, displayName: name });
  };

  const className = classNames(
    { 'text-base-300': transitive },
    { 'bg-base-200': selected },
    { 'text-error-content bg-error': deleted },
    { 'text-success-content bg-success': added },
    'p-2',
    'flex',
    'justify-between',
    'items-center',
    'cursor-pointer'
  );

  const displayNameClassName = classNames({ underline: selected }, 'truncate');

  return (
    <li className={className} onClick={() => onClick(roleId)}>
      <div className={displayNameClassName}>{name}</div>

      {transitive ?? <span className="mr-1 ml-2 badge badge-accent">Transitive</span>}

      {!transitive && onDelete ? ( // Don't show the delete button if the group is transitive, or if onDelete is undefined
        <div>
          <button className="btn btn-ghost btn-xs btn-circle" onClick={handleDeleteClick}>
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      ) : null}
    </li>
  );
}
