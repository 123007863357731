import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../store';
import type { RBACGroup } from '../../../types';

type SliceState = {
  pendingAdds: RBACGroup[];
  pendingDeletes: RBACGroup[];
  selectedGroupId: string;
};

const initialState: SliceState = {
  pendingAdds: [],
  pendingDeletes: [],
  selectedGroupId: '',
};

// RTK slice to track pending adds and deletes of group memberships for currently selected user
export const roleMembershipSlice = createSlice({
  name: 'roleMembership',
  initialState,
  reducers: {
    selectRole: (state, action) => {
      // Set the selected group id or clear it if the payload matches the current state
      state.selectedGroupId = state.selectedGroupId === action.payload.id ? '' : action.payload.id;
    },
    addRoleToPendingDeletes: (state, action) => {
      // If the deleted group id is in the pending adds list, remove it
      if (state.pendingAdds.filter((group) => group.id === action.payload.id).length) {
        state.pendingAdds = state.pendingAdds.filter((group) => group.id !== action.payload.id);
        // Otherwise, add it to the pending deletes list, unless it's already there (in which case remove it)
      } else if (!state.pendingDeletes.filter((group) => group.id === action.payload.id).length) {
        state.pendingDeletes.push(action.payload);
      } else {
        state.pendingDeletes = state.pendingDeletes.filter(
          (group) => group.id !== action.payload.id
        );
      }
    },
    removeRoleFromPendingDeletes: (state, action) => {
      state.pendingDeletes = state.pendingDeletes.filter((group) => group.id !== action.payload.id);
    },
    addRoleToPendingAdds: (state, action) => {
      if (!state.pendingAdds.includes(action.payload)) {
        state.pendingAdds.push(action.payload);
      }
    },
    removeRoleFromPendingAdds: (state, action) => {
      state.pendingAdds = state.pendingAdds.filter((group) => group.id !== action.payload.id);
    },
    clearPendingRoles: (state) => {
      state.pendingAdds = [];
      state.pendingDeletes = [];
    },
  },
});

export default roleMembershipSlice.reducer;

export const {
  addRoleToPendingAdds,
  addRoleToPendingDeletes,
  removeRoleFromPendingAdds,
  removeRoleFromPendingDeletes,
  selectRole,
  clearPendingRoles,
} = roleMembershipSlice.actions;

export const selectPendingRoleAdds = (state: RootState) => state.roleMembership.pendingAdds;
export const selectPendingRoleDeletes = (state: RootState) => state.roleMembership.pendingDeletes;
export const selectSelectedRoleGroupId = (state: RootState) => state.roleMembership.selectedGroupId;
